import React, { useRef } from 'react'
import Swiper from 'react-id-swiper'
import cn from 'classnames'

import IconNext from 'components/Reveal/Navigation/IconNext'
import IconPrev from 'components/Reveal/Navigation/IconPrev'

import style from './styles.module.scss'

const SwiperTestimonial = ({
    children
}) => {
    const ref = useRef(null)

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev()
        }
    }

    return (
        <>
            <Swiper ref={ref} shouldSwiperUpdate>
                {children}
            </Swiper>
            <div
                className={cn(style.btn, style.btnLeft)}
                onClick={goPrev}>
                <IconPrev backgroundColor={'#EC1B2E'} />
            </div>
            <div
                className={cn(style.btn, style.btnNext)}
                onClick={goNext}>
                <IconNext backgroundColor={'#EC1B2E'} />
            </div>
        </>
    )
}

export default SwiperTestimonial
