import React from 'react'
import RevealJs from 'reveal.js'

import Reveal from 'components/Reveal'
import Slide from 'components/Slide'
import Button from 'components/Button'

import IntroSlidesGroup from 'pages/IntroSlides/IntroSlidesGroup'
import ClosingSlidesGroup from 'pages/ClosingSlides/ClosingSlidesGroup'

import SLIDE0 from './images/0.jpg'
import SLIDE1 from './images/1.jpg'
import SLIDE2 from './images/2.jpg'
import SLIDE3 from './images/3.jpg'
import SLIDE4 from './images/4.jpg'
import SLIDE5 from './images/5.jpg'
import SLIDE6 from './images/6.jpg'
import SLIDE7 from './images/7.jpg'
import SLIDE8 from './images/8.jpg'

const TailorMade = ({
    history,
    match
}) => {
    const { id } = match.params
    const [presentation, setPresentation] = React.useState(null)

    React.useEffect(() => {
        (async () => {
            const response = await fetch(`https://${process.env.REACT_APP_API_HOST}/api/presentation/${id}.json`)

            if (!response.ok) {
                console.log('Unable to load presentation')
            }

            const json = await response.json()
            setPresentation(json.presentation)
            RevealJs.configure({ previewLinks: false }) // make reveal.js refresh the previewlink
        })()
    }, [id])
    return (
        <Reveal history={history}>
            <IntroSlidesGroup />
            <Slide backgroundUrl={SLIDE0} />
            <Slide backgroundUrl={SLIDE1} />
            <Slide backgroundUrl={SLIDE2} />
            <Slide backgroundUrl={SLIDE3} />
            <Slide backgroundUrl={SLIDE4} />
            <Slide backgroundUrl={SLIDE5} />
            <Slide backgroundUrl={SLIDE6}>
                {presentation && presentation.livePreview['automotive-desk_of_the_dealer_2019_1'] &&
                <Button style={{
                    position: 'absolute',
                    left: '4.263em',
                    bottom: '3.6915em'
                }} previewLink href={presentation.livePreview['automotive-desk_of_the_dealer_2019_1']}>
                    View Live Example
                </Button>}
            </Slide>
            <Slide backgroundUrl={SLIDE7}>
                <Button style={{
                    position: 'absolute',
                    left: '4.263em',
                    bottom: '3.6915em'
                }} previewLink href={presentation ? presentation.livePreview.crm : 'https://sandbox.shiftone.io/login'}>
                    View Shift One
                </Button>
            </Slide>
            <Slide backgroundUrl={SLIDE8} />
            <ClosingSlidesGroup />
        </Reveal>
    )
}

export default TailorMade
