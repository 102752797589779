import React, { Suspense } from 'react'
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom'

import UpgradeSale from 'pages/UpgradeSale'
import RVD from 'pages/RVD'
import NewYear from 'pages/NewYear'
import ExecutiveRewards from 'pages/ExecutiveRewards'
import ShopAtHome from 'pages/ShopAtHome'
import EmployeePricing from 'pages/EmployeePricing'
import RedTagDays from 'pages/RedTagDays'
import HondaCheckeredFlag from 'pages/HondaCheckeredFlag'
import TruckMonth from 'pages/TruckMonth'
import BoxingWeek from 'pages/BoxingWeek'
import SpringInto from 'pages/SpringInto'
import BlackFriday from 'pages/BlackFriday'
import FallInLove from 'pages/FallInLove'
import TailorMade from 'pages/TailorMade'
import UpgradeLuxury from 'pages/UpgradeLuxury'
import UpgradeSaleXmas from 'pages/UpgradeSaleXmas'
import DeskOfTheDealer from 'pages/DeskOfTheDealer'
import New2You from 'pages/New2You'
import OfferPortal from 'pages/OfferPortal'
import FallInLove2021 from 'pages/FallInLove2021'
import SpringInto2021 from 'pages/SpringInto2021'
import BigThankYou2021 from 'pages/BigThankYou2021'
import FactoryOrder2021 from 'pages/FactoryOrder2021'

const MainLayout = ({ children }) => {
    return (
        <div>
            {children}
        </div>
    )
}

const AppRoute = ({
    component: Component,
    layout: Layout = MainLayout,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => (
            <Layout>
                <Component {...props} />
            </Layout>
        )}
    />
)

const NoLayout = ({ children }) => (<>{children}</>)

const App = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <AppRoute path='/new-2-you-2021-1/:id' component={New2You} layout={NoLayout} />
                    <AppRoute path='/factory-order-2021-1/:id' component={FactoryOrder2021} layout={NoLayout} />
                    <AppRoute path='/big-thank-you-2021-1/:id' component={BigThankYou2021} layout={NoLayout} />
                    <AppRoute path='/offer-portal-2021-1/:id' component={OfferPortal} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-fall-in-love-2021-1/:id' component={FallInLove2021} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-spring-into-a-new-ride-2021-1/:id' component={SpringInto2021} layout={NoLayout} />
                    <AppRoute path='/reveal-the-deal-2020-1/:id' component={RVD} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-new-year-2019-1/:id' component={NewYear} layout={NoLayout} />
                    <AppRoute path='/executive-rewards-2020-1/:id' component={ExecutiveRewards} layout={NoLayout} />
                    <AppRoute path='/shop-at-home-2020-1/:id' component={ShopAtHome} layout={NoLayout} />
                    <AppRoute path='/ford-employee-pricing-2020-1/:id' component={EmployeePricing} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-red-tag-days-2020-1/:id' component={RedTagDays} layout={NoLayout} />
                    <AppRoute path='/honda-checkered-flag-2020-1/:id' component={HondaCheckeredFlag} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-truck-month-2020-1/:id' component={TruckMonth} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-boxing-week-2019-1/:id' component={BoxingWeek} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-spring-into-a-new-ride-2020-1/:id' component={SpringInto} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-black-friday-2019-1/:id' component={BlackFriday} layout={NoLayout} />
                    <AppRoute path='/desk-of-the-dealer-2019-1/:id' component={DeskOfTheDealer} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-fall-in-love-2020-1/:id' component={FallInLove} layout={NoLayout} />
                    <AppRoute path='/tailor-made-2019-1/:id' component={TailorMade} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-luxury-2019-1/:id' component={UpgradeLuxury} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-holiday-2019-1/:id' component={UpgradeSaleXmas} layout={NoLayout} />
                    <AppRoute path='/upgrade-sale-2019-1/:id' component={UpgradeSale} layout={NoLayout} />
                    <AppRoute path='/:id' component={UpgradeSale} layout={NoLayout} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}

export default App
