import React from 'react'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'

import cn from 'classnames'

import Testimonial from 'components/Testimonial'
import SwiperTestimonial from 'components/SwiperTestimonial'

import Slide from 'components/Slide'

import VW from './images/vw.png'
import TOYOTA from './images/toyota.png'
import ACURA from './images/acura.png'
import CHRYSLER from './images/chrysler.png'
import MAZDA from './images/mazda.png'
import HY from './images/h.png'
import GM from './images/gm.png'
import NISSAN from './images/nissan.png'
import KIA from './images/kia.png'
import H from './images/honda.png'
import FORD from './images/ford.png'
import PORSCHE from './images/porsche.png'

import CountrySelection from 'components/CountrySelection'

import IMAGE_1_CAD from './images/CAD/01.jpg'
import IMAGE_1_US from './images/US/01.jpg'

import IMAGE_2_CAD from './images/CAD/02.jpg'
import IMAGE_2_US from './images/US/02.jpg'

import IMAGE_3 from './images/03.jpg'
import IMAGE_4 from './images/04.jpg'
// import IMAGE_5 from './images/05.jpg'
// import IMAGE_6 from './images/06.jpg'
import CALL_SUPPORT_US from './images/US/07.jpg'
import CALL_SUPPORT_CAD from './images/CAD/07.jpg'

import styles from './styles.module.scss'

function ClosingSlidesGroup (props) {
    const { country } = parse(props.history.location.search)
    const isUS = country === 'US'
    return (
        <>
            <Slide backgroundUrl={isUS ? IMAGE_1_US : IMAGE_1_CAD}>
                <CountrySelection />
            </Slide>
            <Slide backgroundUrl={isUS ? IMAGE_2_US : IMAGE_2_CAD}>
                <CountrySelection />
            </Slide>
            <Slide backgroundUrl={IMAGE_3} />
            <Slide backgroundUrl={isUS ? CALL_SUPPORT_US : CALL_SUPPORT_CAD}>
                <CountrySelection />
            </Slide>
            <Slide backgroundUrl={IMAGE_4}>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}>
                    <SwiperTestimonial>
                        <div className={styles.swiperGrid}>
                            <div className={cn(styles.swiperRow, styles.firstRow)}>
                                <Testimonial title='Volkswagen, Fredericton, NB' brand={VW}>
                                    “Great job all around – our sales team loved the originality of the letter.
                                    We had a great show rate and were able to convert many sales!
                                    The Shift Marketing team was fantastic, especially Alysia – great follow up and was there if
                                    I had any questions or concerns! Look forward to running another Desk of the Dealer sale this month!”
                                </Testimonial>
                                <Testimonial title='Hyundai, Grand Falls-Windsor, NL' brand={HY}>
                                    “I could not have been more impressed by our Upgrade Sale Event with Shift Marketing.
                                    It was a simple seamless process, that delivered better sales results than I would have ever expected.
                                    A big thanks to the helpful, professional, and friendly team at Shift Marketing.”
                                </Testimonial>
                                <Testimonial title=' Nissan, Montreal, QC' brand={NISSAN}>
                                    “The month of February was a though month for the car industry as a overall drop in the market
                                    share across Canada but Shift Marketing stepped in took control and turned it around into a 115%
                                    target achievement. Definitely worth the try and definitely will continue with them.”
                                </Testimonial>
                            </div>

                            <div className={styles.swiperRow}>
                                <Testimonial title='Porsche, Woodbridge, ON' brand={PORSCHE}>
                                    “It was Porsche’s first “sale” in many years and the feedback was very positive.
                                    We would want to do this once a year at the same time as what we did this year.”
                                </Testimonial>
                                <Testimonial title='Kia, Sarnia, ON' brand={KIA}>
                                    “This was our most successful Shift event to date. We wrote enough deals to effectively
                                    double our month-to-date and the buzz created has kept traffic up since. Highly recommended.”
                                </Testimonial>
                                <Testimonial title='Mazda, Dieppe, NB' brand={MAZDA}>
                                    “Another successful sale! The staff at Shift Marketing were great to deal with.
                                    The amount of response to our sale was great overall.”
                                </Testimonial>
                            </div>
                        </div>

                        <div className={styles.swiperGrid}>
                            <div className={cn(styles.swiperRow, styles.firstRow)}>
                                <Testimonial title='Honda, Brantford, ON' brand={H}>
                                    “Great job all around – our sales team loved the originality of the letter.
                                    We had a great show rate and were able to convert many sales! The Shift Marketing
                                    team was fantastic, especially Alysia – great follow up and was there if I had any questions
                                    or concerns! Look forward to running another Desk of the Dealer sale this month!”
                                </Testimonial>
                                <Testimonial title='Ford, Hague, SK' brand={FORD}>
                                    “Rosie is awesome, as is the team at Shift Marketing! She walked us through
                                    every step of the sale and was able to help with any issues we had at a moments
                                    notice even on a Saturday evening! The event itself worked very well, and the
                                    Shift Marketing site was easy to use and worked flawlessly. Valley Ford in Hague will be using their services again.”
                                </Testimonial>
                                <Testimonial title='GM, High River, AB' brand={GM}>
                                    “We wanted to get together a campaign quickly and we were able to get everything set up and out quickly.
                                    There was just the right amount of support and follow up from Shawn and the team at Shift Marketing and
                                    our results were better then we were expecting. Look forward to working with you again in the future.”
                                </Testimonial>
                            </div>

                            <div className={styles.swiperRow}>
                                <Testimonial title='Chrysler, Kokomo, IN' brand={CHRYSLER}>
                                    “The second campaign went very smooth! We sold 12 leads which we are very happy with.
                                    We will definitely do another campaign.”
                                </Testimonial>
                                <Testimonial title='Toyota, Grand Falls-Windsor, NL' brand={TOYOTA}>
                                    “Great outcome, and great service from Shift Marketing. Thank you Brittany and team.”
                                </Testimonial>
                                <Testimonial title='Acura, London, ON' brand={ACURA}>
                                    “Alysia was terrific in being able to turn this around for us with a limited time schedule. Well executed!”
                                </Testimonial>
                            </div>
                        </div>

                    </SwiperTestimonial>
                </div>
            </Slide>
            {/* <Slide backgroundUrl={IMAGE_5} /> */}
            {/* <Slide backgroundUrl={IMAGE_6} /> */}
        </>
    )
}

export default withRouter(ClosingSlidesGroup)
