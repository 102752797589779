import React from 'react'

import STARS from './images/stars.png'

import styles from './styles.module.scss'

const startsVotes = {
    0: '0%',
    1: '35%',
    2: '45%',
    3: '55%',
    4: '65%',
    5: '75%'
}

const Testimonial = ({
    children,
    title,
    brand,
    votes = 5
}) => (
    <div className={styles.testimonial}>
        <span className={styles.stars}>
            <div
                style={{
                    backgroundImage: `url(${STARS})`
                }}
                className={styles.image}
            />
            <span
                style={{
                    left: startsVotes[votes] || '70%'
                }}
                className={styles.imagePercent}
            />
        </span>
        <p>{children}</p>
        <div className={styles.description}>
            <div
                className={styles.logo}
                style={{
                    backgroundImage: `url(${brand})`
                }}
            />
            <span className={styles.title}>
                {title}
            </span>
        </div>
    </div>
)

export default Testimonial
