import React from 'react'
import { withRouter } from 'react-router-dom'

import CampaignPoster from 'components/CampaignPoster'
import SwiperCampaigns from 'components/SwiperCampaigns'
import Slide from 'components/Slide'

import SHOP_AT_HOME from './images/ShopAtHome.png'
import EXECUTIVE_REWARDS from './images/ExecutiveRewards.png'
import TRUCK_MONTH from './images/TruckMonth.png'
import RED_TAGS_DAYS from './images/RedTagDays.png'
import FORD_EMPLOYEE_PRICING from './images/FordEmployeePricing.png'
import fallinLove2021 from './images/fallinLove2021.png'
import offerPortal from './images/offerPortal.png'
import springInto2021 from './images/SpringInto2021.png'
import NYNC from './images/NYNC.png'
import TAILOR_MADE from './images/TailorMade.png'
import UPGRADE_LUXURY from './images/UpgradeLuxury.png'
import UPGRADE_SALE from './images/UpgradeSale.png'
import BLACK_FRIDAY from './images/BlackFriday.png'
import BOXING_WEEK from './images/BoxingWeek.png'
import CHECKERED_FLAG from './images/CheckeredFlag.png'
import CHISTMAS from './images/Christmas.png'
import DOTD from './images/DOTD.png'
import RVD from './images/RVD.png'
import factoryOrder from './images/factoryOrder.png'
import bigThankYou2021 from './images/bigThankYou2021.png'
import new2you from './images/new2you.png'

import { SwiperSlide } from 'swiper/react'

const IntroSlidesGroup = ({
    history,
    match
}) => {
    const { id } = match.params
    const { search } = history.location

    const pushRoute = React.useCallback(path => {
        return e => {
            e.preventDefault()
            window.location.href = `/${path}/${id}${search}#/4`
        }
    }, [id, search])

    return (
        <>
            <Slide backgroundUrl='/intro_slides/intro_1.jpg' />
            <Slide backgroundUrl='/intro_slides/intro_2.jpg' />
            <Slide backgroundUrl='/intro_slides/intro_3.jpg' />
            <Slide backgroundUrl='/intro_slides/intro_4.jpg'>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}>
                    <SwiperCampaigns history={history}>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('shop-at-home-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${SHOP_AT_HOME})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('executive-rewards-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${EXECUTIVE_REWARDS})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('reveal-the-deal-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${RVD})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-truck-month-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${TRUCK_MONTH})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('factory-order-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${factoryOrder})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('big-thank-you-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${bigThankYou2021})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-red-tag-days-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${RED_TAGS_DAYS})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('ford-employee-pricing-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${FORD_EMPLOYEE_PRICING})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-fall-in-love-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${fallinLove2021})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('offer-portal-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${offerPortal})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-spring-into-a-new-ride-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${springInto2021})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${NYNC})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('tailor-made-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${TAILOR_MADE})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-luxury-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${UPGRADE_LUXURY})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${UPGRADE_SALE})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-black-friday-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${BLACK_FRIDAY})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-boxing-week-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${BOXING_WEEK})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('honda-checkered-flag-2020-1')}>
                                <div style={{
                                    backgroundImage: `url(${CHECKERED_FLAG})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('upgrade-sale-holiday-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${CHISTMAS})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('desk-of-the-dealer-2019-1')}>
                                <div style={{
                                    backgroundImage: `url(${DOTD})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ maxWidth: '456px' }}>
                            <CampaignPoster onClick={pushRoute('new-2-you-2021-1')}>
                                <div style={{
                                    backgroundImage: `url(${new2you})`
                                }} />
                            </CampaignPoster>
                        </SwiperSlide>
                        <SwiperSlide style={{ minWidth: '456px' }} />
                    </SwiperCampaigns>
                </div>
            </Slide>
        </>
    )
}

export default withRouter(IntroSlidesGroup)
