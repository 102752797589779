import React from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import styles from './styles.module.scss'
import { parse } from 'query-string'

import FLAG_CAD from './images/FLAG_CAD.jpg'
import FLAG_US from './images/FLAG_US.jpg'

const CountrySelection = ({
    history
}) => {
    const {
        hash,
        pathname,
        search
    } = history.location

    const { country } = parse(search)

    const goCA = React.useCallback(() => history.push(`${pathname}?country=CAD${hash}`), [pathname, hash, history])

    const goEU = React.useCallback(() => {
        history.push(`${pathname}?country=US${hash}`)
    }, [pathname, hash, history])

    const isCad = country !== 'US'
    const isUS = country === 'US'

    return (
        <div className={styles.countrySelection}>
            <div className={cn(styles.btn, {
                [styles.btnActive]: isCad
            })} onClick={goCA}>
                <span style={{
                    backgroundImage: `url(${FLAG_CAD})`
                }} />
            </div>
            <div className={cn(styles.btn, {
                [styles.btnActive]: isUS
            })} onClick={goEU}>
                <span style={{
                    backgroundImage: `url(${FLAG_US})`
                }} />
            </div>
        </div>
    )
}

export default withRouter(CountrySelection)
