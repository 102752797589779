import React from 'react'
// import Button from 'components/Button'

import styles from './styles.module.scss'

export default function CampaignPoster ({
    children,
    onClick
}) {
    return (
        <div className={styles.campaignContainer}>
            <div
                className={styles.campaignPoster}
                onClick={onClick}>
                {children}
            </div>
        </div>
    )
}
