import React from 'react'
import RevealJs from 'reveal.js'

import Reveal from 'components/Reveal'
import SwiperTestimonial from 'components/SwiperTestimonial'
import Slide from 'components/Slide'
import Testimonial from 'components/Testimonial'
import Button from 'components/Button'

import VW from './images/vw.png'
import TOYOTA from './images/toyota.png'
import ACURA from './images/acura.png'
import CHRYSLER from './images/chrysler.png'
import MAZDA from './images/mazda.png'
import HY from './images/h.png'
import GM from './images/gm.png'
import HONDA from './images/honda.png'
import NISSAN from './images/nissan.png'
import KIA from './images/kia.png'
import FORD from './images/ford.png'
import PORSCHE from './images/porsche.png'

import cn from 'classnames'

import IntroSlidesGroup from 'pages/IntroSlides/IntroSlidesGroup'
import ClosingSlidesGroup from 'pages/ClosingSlides/ClosingSlidesGroup'

import styles from './styles.module.scss'

const UpgradeSale = ({
    match,
    history
}) => {
    const { id } = match.params
    const [presentation, setPresentation] = React.useState(null)

    React.useEffect(() => {
        (async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_API_HOST}/api/presentation/${id}.json`)

                if (!response.ok) {
                    console.error('Unable to load presentation')
                }

                const json = await response.json()
                setPresentation(json.presentation)
                RevealJs.configure({ previewLinks: false }) // make reveal.js refresh the previewlink
            } catch (ex) {
                console.error(ex)
            }
        })()
    }, [id])

    return (
        <Reveal history={history}>
            <IntroSlidesGroup />
            <Slide backgroundUrl='/slides/01.jpg' />
            <Slide backgroundUrl='/slides/02.jpg' />
            {/* <Slide backgroundUrl='/slides/03.jpg' /> */}
            <Slide backgroundUrl='/slides/04.jpg' />
            <Slide backgroundUrl='/slides/05.jpg' />
            <Slide backgroundUrl='/slides/06.jpg' />
            <Slide backgroundUrl='/slides/07.jpg'>
                {presentation && presentation.livePreview['automotive-upgrade_sale_2019_1'] && (
                    <Button style={{
                        position: 'absolute',
                        left: '4.263em',
                        bottom: '3.6915em'
                    }} previewLink href={presentation.livePreview['automotive-upgrade_sale_2019_1']}>
                        View Live Example
                    </Button>
                )}
            </Slide>
            <Slide backgroundUrl='/slides/08.jpg'>
                <Button style={{
                    position: 'absolute',
                    left: '4.263em',
                    bottom: '3.6915em'
                }} previewLink href={presentation ? presentation.livePreview.crm : 'https://sandbox.shiftone.io/login'}>
                    View Shift One
                </Button>
            </Slide>
            <Slide backgroundUrl='/slides/09.jpg' />
            <Slide backgroundUrl='/slides/11.jpg'>
                <div style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}>
                    <SwiperTestimonial>
                        <div className={styles.swiperGrid}>
                            <div className={cn(styles.swiperRow, styles.firstRow)}>
                                <Testimonial title='Volkswagen, Fredericton, NB' brand={VW}>
                                    “Great job all around – our sales team loved the originality of the letter.
                                    We had a great show rate and were able to convert many sales!
                                    The Shift Marketing team was fantastic, especially Alysia – great follow up and was there if
                                    I had any questions or concerns! Look forward to running another Desk of the Dealer sale this month!”
                                </Testimonial>
                                <Testimonial title='Hyundai, Grand Falls-Windsor, NL' brand={HY}>
                                    “I could not have been more impressed by our Upgrade Sale Event with Shift Marketing.
                                    It was a simple seamless process, that delivered better sales results than I would have ever expected.
                                    A big thanks to the helpful, professional, and friendly team at Shift Marketing.”
                                </Testimonial>
                                <Testimonial title=' Nissan, Montreal, QC' brand={NISSAN}>
                                    “The month of February was a though month for the car industry as a overall drop in the market
                                    share across Canada but Shift Marketing stepped in took control and turned it around into a 115%
                                    target achievement. Definitely worth the try and definitely will continue with them.”
                                </Testimonial>
                            </div>
                            <div className={styles.swiperRow}>
                                <Testimonial title='Porsche, Woodbridge, ON' brand={PORSCHE}>
                                    “It was Porsche’s first “sale” in many years and the feedback was very positive.
                                    We would want to do this once a year at the same time as what we did this year.”
                                </Testimonial>
                                <Testimonial title='Kia, Sarnia, ON' brand={KIA}>
                                    “This was our most successful Shift event to date. We wrote enough deals to effectively
                                    double our month-to-date and the buzz created has kept traffic up since. Highly recommended.”
                                </Testimonial>
                                <Testimonial title='Mazda, Dieppe, NB' brand={MAZDA}>
                                    “Another successful sale! The staff at Shift Marketing were great to deal with.
                                    The amount of response to our sale was great overall.”
                                </Testimonial>
                            </div>
                        </div>

                        <div className={styles.swiperGrid}>
                            <div className={cn(styles.swiperRow, styles.firstRow)}>
                                <Testimonial title='Honda, Brantford, ON' brand={HONDA}>
                                    “Great job all around – our sales team loved the originality of the letter.
                                    We had a great show rate and were able to convert many sales! The Shift Marketing
                                    team was fantastic, especially Alysia – great follow up and was there if I had any questions
                                    or concerns! Look forward to running another Desk of the Dealer sale this month!”
                                </Testimonial>
                                <Testimonial title='Ford, Hague, SK' brand={FORD}>
                                    “Rosie is awesome, as is the team at Shift Marketing! She walked us through
                                    every step of the sale and was able to help with any issues we had at a moments
                                    notice even on a Saturday evening! The event itself worked very well, and the
                                    Shift Marketing site was easy to use and worked flawlessly. Valley Ford in Hague will be using their services again.”
                                </Testimonial>
                                <Testimonial title='GM, High River, AB' brand={GM}>
                                    “We wanted to get together a campaign quickly and we were able to get everything set up and out quickly.
                                    There was just the right amount of support and follow up from Shawn and the team at Shift Marketing and
                                    our results were better then we were expecting. Look forward to working with you again in the future.”
                                </Testimonial>
                            </div>
                            <div className={styles.swiperRow}>
                                <Testimonial title='Chrysler, Kokomo, IN' brand={CHRYSLER}>
                                    “The second campaign went very smooth! We sold 12 leads which we are very happy with.
                                    We will definitely do another campaign.”
                                </Testimonial>
                                <Testimonial title='Toyota, Grand Falls-Windsor, NL' brand={TOYOTA}>
                                    “Great outcome, and great service from Shift Marketing. Thank you Brittany and team.”
                                </Testimonial>
                                <Testimonial title='Acura, London, ON' brand={ACURA}>
                                    “Alysia was terrific in being able to turn this around for us with a limited time schedule. Well executed!”
                                </Testimonial>
                            </div>
                        </div>
                    </SwiperTestimonial>
                </div>
            </Slide>
            {/* <Slide backgroundUrl='/slides/13.jpg' />
            <Slide backgroundUrl='/slides/14.jpg' /> */}
            <ClosingSlidesGroup />
        </Reveal>
    )
}

export default UpgradeSale
